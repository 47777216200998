<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>Operadores</h1>
      </v-flex>
      <v-flex xs12 md3 class="text-center d-none d-md-block">
        <h3>Nº Operadores</h3>
        <v-chip>{{ integrator.operators.length }}</v-chip>
      </v-flex>
      <v-flex xs6 md3 class="text-center d-none d-md-block">
        <h3>Usuarios registrados</h3>
        <v-chip>{{ integrator.numUsers }}</v-chip>
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-card elevation="1" class="mt-3">
          <v-simple-table dense>
            <template>
              <thead>
                <tr>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in integrator.operators"
                  :key="item.operatorId"
                  v-on:click="onOperatorSelected(item)"
                >
                  <td>{{ item.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>

      <v-flex xs12 md6>
        <IntegratorMediaAccess
          :operatorIntegrator="operatorIntegratorSelected"
          readOnly
        ></IntegratorMediaAccess>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import IntegratorMediaAccess from "../components/IntegratorMediaAccess.vue";

export default {
  name: "Operators",
  components: { IntegratorMediaAccess },
  data: () => ({
    requiredRule: [v => (v != null && v != "") || "Campo obligatorio"],
    editorDialog: false,
    editMode: false,
    start: false,
    end: false,
    operatorIntegratorSelected: null,
  }),
  created() {},
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    integrator() {
      return this.$store.getters.getIntegrator;
    },
  },
  methods: {
    onOperatorSelected: function(item) {
      this.operatorIntegratorSelected = {
        integratorId: this.integrator.integratorId,
        operatorId: item.operatorId,
        integrator: this.integrator.name,
      };
    },
  },
};
</script>

<style scoped></style>
